import { useRef, useState } from "react";
import styled from "styled-components";
import Mute from "../components/Mute/Mute";
import Image from "../components/Image/index";
import TwitterIcon from "@mui/icons-material/Twitter";
import BurnBackground from "../assets/images/burnbackground.png";
import BurnLogo from "../assets/images/burnlogo.png";
import { Button, Input, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Label } from "@mui/icons-material";
import { wallets } from "../wallets/walletList";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 850px;
  overflow: hidden;
  a {
    text-decoration: none;
  }
  .twitter-icon {
    font-size: 23px;
    color: #dedede70;
    padding: 0px 20px 40px 2px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
      padding-left: 20px;
    }
  }
  .twitter-icon:hover {
    color: #1da1f2 !important;
  }
  .burn-logo {
    padding-top: 50px;
    @media screen and (max-width: 950px) {
      padding-top: 26px;
    }
    @media screen and (max-width: 799px) {
      width: 175px;
    }
    @media screen and (max-width: 510px) {
      padding-top: 10px;
      width: 150px;
    }
    @media screen and (max-width: 330px) {
      width: 50%;
    }
  }
  .nav-bar {
    align-items: center;
    position: absolute;
    top: 25px;
    right: 40px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 950px) {
      padding-top: 20px;
      top: 0;
      right: 0;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
    }
  }
  .nav-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .heading {
    padding-top: 100px;
    font-family: "Lato";
    font-weight: 300;
    color: #fff;
    font-size: 26px;
    @media only screen and (max-width: 775px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 510px) {
      padding-top: 25px;
      font-size: 20px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  .goldenkey-container {
    padding-top: 40px;
    margin: 0 auto;
    width: 900px;
    margin-bottom: 30px;
    @media only screen and (max-width: 910px) {
      width: 600px;
    }
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .studio-pass-container {
    width: 500px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .studio-pass-video-container {
    width: 600px;
    margin-bottom: 30px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
  }
  .studio-pass-video {
    border: 1.45px solid #aba14a;
    width: 100%;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .sub-header {
    padding-top: 40px;
    color: #fff;
    font-family: "Lato";
    font-weight: 300;
    margin-bottom: 0;
    font-size: 20px;
    @media only screen and (max-width: 775px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .form-label {
    padding-top: 10px;
    padding-bottom: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  }
  .form-input {
    border-radius: 5px;
    height: 45px;
    background-color: #dcdcdc;
    margin-bottom: 10px;
    padding: 10px;
    color: #000;
    width: 400px;
    @media only screen and (max-width: 450px) {
      width: 300px;
      height: 25px;
    }
  }
  .form-input:focus {
    outline: none !important;
    border-color: #4046c2 !important;
    box-shadow: 0 0 10px #4046c2 !important;
  }
  .studio-pass {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .echo-key {
    font-family: "Lato";
    color: #fff;
    margin-bottom: 20px;
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
    text-align: center;
  }
  .transaction-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (max-width: 910px) {
      flex-direction: column;
    }
  }
  .transaction-text {
    cursor: pointer;
    font-family: "Lato";
    font-weight: 300;
    font-size: 15px;
    color: #fff;
    text-align: center;
    @media only screen and (max-width: 510px) {
      font-size: 12px;
    }
  }
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
    padding-top: 150px;
  }
  .check-button-container {
    padding-top: 20px;
    margin: 0 auto;
  }
  .check-button {
    margin: 0 auto;
    border-radius: 0;
    width: 120px;
    font-size: 15px;
    font-family: "Lato";
    height: 20px;
    padding: 18px 0px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 510px) {
      width: 110px;
      font-size: 12px;
    }
    @media only screen and (max-width: 330px) {
      width: 80px;
      font-size: 12px;
    }
  }
  .check-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
  .eligible {
    color: #fff;
    font-family: "Lato";
    font-weight: 300;
    text-align: center;
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  .not-eligible {
    color: #fff;
    font-family: "Lato";
    font-weight: 300;
    text-align: center;
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  a {
    text-decoration: none;
    margin-top: 40px;
  }
  .view-addresses {
    cursor: pointer;
    color: #fff;
    font-family: "Lato";
    font-weight: 300;
    text-align: center;
  }
  .view-addresses:hover {
    opacity: 0.7;
  }
`;

function WalletCheckerPage() {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [success, setSuccess] = useState(false);

  const WalletAddressSchema = Yup.object().shape({
    walletAddress: Yup.string().required("is required"),
  });
  return (
    <StyledDiv
      style={{
        background: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${BurnBackground})`,
      }}
    >
      <div className="nav-bar">
        <div className="nav-icons">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/zeropointxyz"
          >
            <TwitterIcon className="twitter-icon" />
          </a>
        </div>
      </div>
      <Image
        className="burn-logo"
        src={BurnLogo}
        alt="Zero Point Studios Logo"
      ></Image>
      <h1 className="heading">THE ORDER AWAITS</h1>
      <h2 className="sub-header">Check the status of your entry:</h2>
      <Formik
        initialValues={{
          walletAddress: "",
        }}
        validationSchema={WalletAddressSchema}
        validateOnBlur={true}
        onSubmit={(values) => {
          setSuccess(false);
          setSubmitClicked(true);
          wallets.map((data, index) => {
            if (
              values.walletAddress.toLowerCase() ===
              data.walletAddress.toLowerCase()
            ) {
              setSuccess(true);
            }
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="form-container">
            <Label className="form-label">Wallet Address</Label>
            <Input
              required
              className="form-input"
              name="walletAddress"
              id="walletAddress"
              value={values.walletAddress || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            <div className="check-button-container">
              <Button className="check-button" type="submit">
                Check
              </Button>
            </div>
            {submitClicked && (
              <>
                {success ? (
                  <p className="eligible">
                    Welcome to the Order of the Watcher.
                  </p>
                ) : (
                  <p className="not-eligible">
                    Your Induction could not be granted.
                    <br />
                    More opportunities to join the Order are coming…
                  </p>
                )}
              </>
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://zeropointstudios.mypinata.cloud/ipfs/QmPyhSnLPT32mvs1RWJ1LwTuRTaZNhjNorApg6q28737uu"
            >
              <p className="view-addresses">Click here to view all winners</p>
            </a>
          </Form>
        )}
      </Formik>
      <div className="footer">
        <Typography className="copyright" variant="body2">
          Copyright &copy; 2024 Zero Point | All Rights Reserved
        </Typography>
      </div>
    </StyledDiv>
  );
}

export default WalletCheckerPage;

import { Typography } from "@mui/material";
import studioPass from "../assets/images/studio-pass-button-alone.png";
import nadaText from "../assets/images/nada.png";
import line from "../assets/images/Line.png";
import styled from "styled-components";
import { useRef, useState } from "react";
// import studioPassVideo from "../assets/videos/StudioPass.mp4";
import Mute from "../components/Mute/Mute";
import StudioBackground from "../assets/images/StudioBackground.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Navbar from "../components/NavBar/Navbar";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  background-color: #000;
  background-position-x: center;
  a {
    text-decoration: none;
  }
  .element-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 600px;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .paragraph-one {
    font-family: "Lato";
    color: #fff;
    font-size: 15px;
    text-align: justify;
    margin-bottom: 30px;
    line-height: 18px;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      margin-bottom: 25px;
    }
  }
  .studio-pass-container {
    width: 500px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .studio-pass-video-container {
    width: 600px;
    margin-bottom: 30px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
  }
  .studio-pass-video {
    border: 1.45px solid #cbb589;
    width: 100%;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .studio-pass {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .studio-pass-header {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    font-family: "Cinzel";
    letter-spacing: 6px;
    color: #cbb589;
    @media only screen and (max-width: 600px) {
      font-size: 34px;
    }
  }
  .echo-key {
    font-family: "Lato";
    color: #fff;
    margin-bottom: 20px;
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
    text-align: left;
  }
  .paragraph-two {
    font-family: "Lato";
    font-size: 15px;
    color: #fff;
    text-align: justify;
    margin-bottom: 40px;
    line-height: 18px;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
  .line {
    margin: 20px auto;
    width: 100%;
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .mute-button {
    position: absolute;
    z-index: 2;
  }
  .journey-text {
    text-align: center;
    padding-top: 20px;
    color: #504a3e;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  .nada-anchor {
    display: inline-block;
    height: 100%;
    width: 50%;
    padding-top: 40px;
    margin: 0 auto;
    @media only screen and (max-width: 450px) {
      width: 64%;
    }
  }
  .nada {
    width: 100%;
  }
  .line-two {
    width: 40%;
    padding-top: 54px;
    margin: 0 auto;
  }
  .footer {
    margin: 20px auto;
    color: white;
    @media only screen and (max-width: 650px) {
      margin-top: 50px;
      font-size: 10px;
    }
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
  }
  .details {
    font-family: "Lato";
    color: #fff;
    font-size: 15px;
    margin-bottom: 60px;
    text-align: justify;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
  .nada-anchor-list {
    text-decoration: none;
    color: #cbb589;
  }
  .nada-anchor-list:hover {
    opacity: 0.7;
  }
  .burn-anchor {
    font-family: "Lato";
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }
  .burn-anchor:hover {
    color: #cbb589;
  }
  .litepaper-anchor {
    font-family: "Lato";
    font-size: 15px;
    text-decoration: none;
    color: #cbb589;
    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }
  }
  .litepaper-anchor:hover {
    opacity: 0.7;
  }
`;

function StudioPass() {
  const [muted, setMuted] = useState(true);
  const handleToggleMute = () => {
    setMuted((current) => !current);
    if (!studioPassVideoElementRef.current) return;
    studioPassVideoElementRef.current.muted = !muted;
  };
  const studioPassVideoElementRef = useRef<HTMLVideoElement>(null);

  return (
    <StyledDiv
      className="studio-pass-page"
      style={{ backgroundImage: `url(${StudioBackground})` }}
    >
      <Navbar />

      <div className="element-container">
        <div className="studio-pass-container">
          <h1 className="studio-pass-header">STUDIO PASS</h1>
          {/* <img className="studio-pass" src={studioPass} alt="Studio Pass" /> */}
        </div>
        {/* <Typography className="paragraph-one">
          Zero Point is a Web3 entertainment studio that focuses on IP creation
          through live-action films tied to an NFT ecosystem. We currently have
          two live-action feature films in development along with their
          respective Web3 experiences and collections.
        </Typography>
        <Typography className="paragraph-one">
          Our mission is to create an IP Universe for the metaverse. With 40+
          fully developed stories in our vault, our catalogue of diverse IP
          spans across multiple genres, worlds, settings, time periods and
          characters, all of which are combined into ONE interconnected
          storyline. We call this the Echoverse.
        </Typography> */}
        <Typography className="paragraph-one">
          Zero Point is proud to present the{" "}
          <a
            className="litepaper-anchor"
            target="_blank"
            rel="noopener noreferrer"
            href="https://opensea.io/collection/echokey"
          >
            Echo Key
          </a>
          , your exclusive all-access token to all IP, productions and events
          across the entire Zero Point Ecosystem. The Echo Key collection is our
          debut into the NFT market, a free mint with an original supply of 350
          that was airdropped to the public on January 21, 2023. Owning an Echo
          Key makes you an Agent of the Watcher and will unlock free and
          enhanced access to all of Zero Point's future primary collections,
          exclusive positioning within the Echosis, behind-the-scenes
          experiences, pre-screenings and red carpet premieres, as well as
          unprecedented involvement in the making of our films.
        </Typography>
        <Typography className="paragraph-one">
          1.{" "}
          <a
            className="nada-anchor-list"
            target="_blank"
            rel="noopener noreferrer"
            href="https://nadaofthesun.com/"
          >
            <em>Nada of the Sun</em>
          </a>{" "}
          - A fantasy sci-fi epic centered around Nada, the daughter of a living
          god, who is kidnapped in a plot to seize her father's empire and bury
          the key to mankind's salvation for all time. This IP will be
          introduced to the market through our debut PFP release.
        </Typography>
        <Typography className="paragraph-one">
          2. <em>TTP</em> - <em>TTP</em> (code-name) is an independent
          character-driven, fantasy thriller currently in development. Connected
          to one of the flagship properties within the Echoverse, <em>TTP</em>{" "}
          gives a first-look into the dark underground world of a centuries old
          killer who is set loose in the heart of Los Angeles. This IP will be
          introduced to the market through a series of pop-up events on the
          blockchain.
        </Typography>
        <Typography className="paragraph-one">
          The Echo Key has a burn mechanism that went live on January 24, 2023.
          If you hold 7 or more Echo Keys, you may burn 6 to upgrade the 7th to
          a Master Echo Key. Master Echo Keys will receive enhanced variations
          of traditional Echo Key benefits (both in quality and quantity) as
          well as access to exclusive rewards.
        </Typography>
        <Typography className="paragraph-one">
          As of July 11, 2023, 186 Echo Keys have been burned to create 31
          Master Echo Keys, leaving a remaining supply of 164. Only a maximum of
          50 Master Echo Keys can ever be created, as small groups allow for the
          delivery of unmatched benefits.
        </Typography>
        <Typography className="paragraph-one">
          Click{" "}
          <a
            className="litepaper-anchor"
            href="https://litepaper.zeropointstudios.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          to read more about Zero Point and our vision.
        </Typography>
        {/* <span className="details">DETAILS COMING SOON</span> */}
        <span className="echo-key">
          ECHO KEY{" "}
          <a
            className="burn-anchor"
            href="./burn"
            target="_blank"
            rel="noopener noreferrer"
          >
            - CLICK HERE TO BURN AND UPGRADE
          </a>
        </span>
        <div onClick={handleToggleMute} className="studio-pass-video-container">
          <div className="mute-button">
            <Mute isMuted={muted} />
          </div>
          <video
            ref={studioPassVideoElementRef}
            src="https://d2rvhkljbcb1ck.cloudfront.net/StudioPass.mp4"
            className="studio-pass-video"
            webkit-playsinline="true"
            playsInline
            loop
            muted
            autoPlay
          ></video>
        </div>
        <Typography className="paragraph-two">
          Forged by the Watcher before the beginning of time, the Echo Key is an
          instrument of immeasurable power rumored to have originated from the
          Mecca Stone, a rock fabled for its ability to exist within all
          dimensions simultaneously. Although its true power remains a closely
          guarded secret, even from those who wield it, the Echo Key allows the
          bearer passage into the Hall of Echoes, a realm between realms that
          interconnects the doorways of reality. The Echo Key is a tool given
          only to agents of the Order of the Watcher in order to carry out
          missions throughout space and time, and none have ever fallen into
          enemy hands.
        </Typography>
        <img className="line" src={line} alt="Line" />
        <div className="nav-container">
          <Typography className="journey-text">
            CONTINUE YOUR JOURNEY:
          </Typography>
          <a
            className="nada-anchor"
            target="_blank"
            rel="noopener noreferrer"
            href="https://nadaofthesun.com/"
          >
            <img className="nada" src={nadaText} alt="Nada Da Sun" />
          </a>
        </div>
        <img className="line-two" src={line} alt="Line" />
        <div className="footer">
          <Typography className="copyright" variant="body2">
            Copyright &copy; 2024 Zero Point | All Rights Reserved
          </Typography>
        </div>
      </div>
    </StyledDiv>
  );
}

export default StudioPass;
